@media(min-width:576px) {
    /* Make font and cell size normal */
    .score {
        width: 24px;
    }
}

@media(max-width:576px) {
    /* Make font and cell size normal */
    .score {
        width: 20px;
    }

    .round table td {
        padding: 0.2rem;
    }

    .round {
        font-size: small;
    }
}



@media(min-width:992px) {
    .round {
        display:flex;
    }


}

.round table {
    table-layout: fixed; 
}

.result-hole-row td {
    font-weight: 200;
}

.result-score-row {
    font-weight: 400;
}

.score {
    margin:auto;
    text-align: center; 
}

.score p {
    margin:0px;
}
 /* TO PAR */
.score-birdie { 
    border-radius: 50%; 
    background-color: rgb(255, 100, 100) !important; 
    color: white !important; 
} 

.score-eagle { 
    border-radius: 50%; 
    background-color: rgb(204,204,0) !important; 
} 

.score-bogey { 
    border-radius: 5%; 
    background-color: lightblue !important; 
} 

.score-dblbogey { 
    border-radius: 5%; 
    background-color: rgb(0,0,180) !important; 
    color: white !important; 
} 

.score-worse { 
    border-radius: 5%; 
    background-color: black !important; 
    color: white !important; 
} 

/* STATS */
.score-best1 {
    border-radius: 50%; 
    background-color: rgb(21, 155, 3) !important; 
    color: white !important;  
    width:100%;
}
.score-best2 {
    border-radius: 50%; 
    background-color: rgba(21, 155, 3, 0.7) !important; 
    color: white !important;  
    width:100%;
}
.score-best3 {
    border-radius: 50%; 
    background-color: rgba(21, 155, 3, 0.6) !important; 
    color: white !important;  
    width:100%;
}

.score-worse1 {
    border-radius: 50%; 
    background-color: rgba(146, 5, 5, 0.9) !important; 
    color: white !important;   
    width:100%;
}
.score-worse2 {
    border-radius: 50%; 
    background-color: rgba(146, 5, 5, 0.7) !important; 
    color: white !important;  
    width:100%;
}
.score-worse3 {
    border-radius: 50%; 
    background-color: rgba(146, 5, 5, 0.6) !important; 
    color: white !important;  
    width:100%;
}