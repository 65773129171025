@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@700&display=swap');

/*#region Bootstrap-overrides */

h1, h2, h3, h4, h5, h6 {
  color : white !important;
}
/*#endregion*/

/*#region APP*/
.App {
  text-align: center;
}

.main-content {
  margin-top: 20px;
  padding-bottom:50px;
}

.error-message {
  color: red;
}
/*#endregion*/

/*#region Footer*/
/******************************************************
  Footer
 */
 @media(max-width:578px) {
  .footer {
    max-height:35px !important;
    padding-bottom:5px;
  }
  .footer img {
    max-height: 30px !important;
    padding-left:10px !important;
  }
 }

.footer {
  align-items: center;
  display:flex;
  padding-top:5px;
  padding-bottom: 5px;
  max-height:80px;
  background: black;
  opacity: 0.6;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  width: 100%;    
  z-index: 1000;
}
.footer-container {
  margin:auto;
}
.footer img {
  max-height: 70px;
  padding-left:40px;
}

.footer-ruff {
  height: 75%;
}
/*#endregion*/

/*#region Menu*/
.menu-bar {
  background: black;
  opacity: 0.5;
  font-weight: 600;
}

.menu-bar a {
  color: white !important;
}

@media(max-width:578px) {
  .menu-bar a {
    font-size: small;
  }
}
.brand-image {
  height: 30px;
}

.dropdown-menu {
  background : rgb(0,0,0,0.9) !important;
}
/* Make sure navbar is visible on top of other components */
.navbar {
  z-index: 1000;
}
/*#endregion*/

/*#region STARTPAGE*/
.start-page-wrapper {
  display:flex;
  align-items: center;
  justify-content: center;
  height:90vh;

}


.start-page-container {
  margin: auto;
  color: white; 
  text-align: center;
  position:relative;
  /* margin-top:300px; */
  /* height:100vh; */
}

.advert-carousel {
  padding-top: 10px;
}

.advert-image{
  max-height: 200px;
}

.advert-carousel .carousel .slide {
  height:200px;
}

.carousel {
  height:200px;
}

@media(max-width:576px) {
  .start-page-container {
    margin-top: 100px;
  }

  .show-on-web {
    display:none !important;
  }
  .advert-image{
    max-height: 100px;
  }
} 

.start-page-header {
  font-size: 50px;
}

.start-page-divider {
  color: #FFAC26;
  height: 10px;
  width:50%;
  margin: auto;
}

.start-page-divider hr {
  border-top:5px solid #FFAC26;
  border-radius: 10px;
  opacity: 0.9;

}

.button-read-more {
  width:150px;
}
/*#endregion*/

/*#region ABOUTPAGE*/
.about-page-container {
  color: white;
  font-weight: 600;
  border-radius: 20px;
  padding:20px;

  background: rgb(0,0,0,0.7);
  margin-bottom:20px;
}

.about-page-container p {
  margin-top: 5px;
}
.about-page-container a {
  color: white;
}
@media(max-width:578px) {
  .about-page-container {
    font-size: small;
    font-weight: 400;
  }
 }


/*#endregion*/


.table-800 {
  max-width: 800px;
}

/*
*/
.editevent-number-of-players {
  font-weight: 600;
  padding:5px;
}


 @media(min-width:577px) {
  .show-on-mobile {
    display: none !important;
  }
 }
/* -------------------------------------*/



