@media(max-width:576px) {
    .player-stats {
        font-size: small;
    }
}

.player-stat-frame {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
}
.player-stat-eclictic-table {
    margin-top:10px;
    padding-top:10px;
    border-top: 2px solid darkgray;
}

.player-name {
    font-weight: bold;
  }
  
.player-stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.player-stat-group {
    display: flex;
    flex-direction: row;
}
.player-stat {
    display: block;
    padding: 5px;
    border-right: 1px solid lightgrey;
    min-width: 48px;
}

.player-stat-header {
    font-weight: 600;
    color: darkgrey;
    padding-bottom: 5px;
}

.player-stat-value {
    margin:auto;
    text-align: center;
}
