/*
    Generic
*/
.leaderboard {
    padding-top:10px;  
    padding-bottom: 20px;
}

.leaderboard-table tr, .leaderboard-table td {
    background-color: transparent !important;
}
.leaderboard-table {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 1px solid white;
    background-color: white;
   }

   .leaderboard-table tbody {
    border-bottom: 0 solid transparent;
   }

   /* Apply a border to the right of all but the last column */
   .leaderboard-table th:not(:last-child),
   .leaderboard-table td:not(:last-child) {
    border-right: 1px solid var(--bs-table-border-color);
   }
   
   /* Apply a border to the bottom of all but the last row */
   /* .leaderboard-table>thead>tr:not(:last-child)>th,
   .leaderboard-table>thead>tr:not(:last-child)>td, */
   .leaderboard-table>tbody>tr:not(:last-child)>th,
   .leaderboard-table>tbody>tr:not(:last-child)>td,
   .leaderboard-table>tfoot>tr:not(:last-child)>th,
   .leaderboard-table>tfoot>tr:not(:last-child)>td,
   .leaderboard-table>tr:not(:last-child)>td,
   .leaderboard-table>tr:not(:last-child)>th,
   .leaderboard-table>thead:not(:last-child),
   .leaderboard-table>tbody:not(:last-child),
   .leaderboard-table>tfoot:not(:last-child) {
    border-bottom: 1px solid var(--bs-table-border-color);
   }
.leaderboard-col-icon {
    max-width:20px;
}

.leaderboard-col-pos {
    max-width:15px;
}

.leaderboard-fixed {
    max-width:40px;
}
.leaderboard-prev-position {
    font-size: smaller;
}

.leaderboard-event-header {
    font-weight: 600;
}
.leaderboard-event-result-section {
    padding-bottom: 3rem;
}
.leaderboard-more-result {
    font-size: small !important;
    font-weight: 200 !important;
    width: 100%;
}

.leaderboard-name {
    overflow: hidden;
    white-space: nowrap;
}
.leaderboard-row {
    cursor: pointer;
}

.leaderboard-expanded-section {
    margin-bottom:10px;
    padding-bottom:30px;
    border-bottom: 2px solid darkgray;
}

.leaderboard-expanded-header {
    padding-right:5px;
    font-weight: 600;
    text-align: right;
}

.leaderboard-value {
    text-align: center;
}

.not-result {
    font-weight: 200;
}

.result {
    font-weight: 600;
}

.result-under-par {
    color:red !important;
}
.selectable {
    cursor: pointer;
}


/*
    Mobile settings
*/

.leaderboard-table thead, .leaderboard-table tbody {
    font-size: small;
}

.leaderboard-table td {
    padding: .3rem !important;
}

/*
    Tablets and up
*/ 
@media(min-width:768px) { 
    .leaderboard-table thead, .leaderboard-table tbody {
        font-size: medium;
    }

    .leaderboard-fixed {
        width: 70px;
    }

    .leaderboard-value {
        width:70px;
    }

    .leaderboard-table td {
        padding: .5rem !important;
    }
 }






